import { AuthenticateWithRedirectCallback, ClerkProvider } from '@clerk/clerk-react';
import { Toaster } from "@repo/ui/components/ui/sonner";
import "@repo/ui/styles.css";
import * as Sentry from "@sentry/react";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { StrictMode } from "react";
import { createRoot } from 'react-dom/client';
import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { routeObjects } from "./config/routes";
import './index.css';
import { ErrorBoundary } from "./organisms";
import { Auth } from "./routes/auth";
import { SignIn } from "./routes/auth.sign-in";
import { SignUp } from "./routes/auth.sign-up";
import { Verify } from "./routes/auth.sign-up.verify";
import { VerifyEmailAddress } from "./routes/auth.sign-up.verify-email-address";
import { Root } from "./routes/root";
import { RootIndex } from "./routes/root._index";

const SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
});


const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}

const queryClient = new QueryClient()

const router = createBrowserRouter([
  {
    path: "/auth",
    element: <Auth />,
    children: [
      {
        path: "sign-up/sso-callback",
        element: <AuthenticateWithRedirectCallback />,
      },
      {
        path: "sign-in/sso-callback",
        element: <AuthenticateWithRedirectCallback />,
      },
      {
        path: "sign-up/verify-email-address",
        element: <VerifyEmailAddress />,
      },
      {
        path: "sign-up/verify",
        element: <Verify />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "sign-in/factor-one",
        element: <SignIn />,
      },
      {
        path: "sign-in",
        element: <SignIn />,
      },
    ],
    errorElement: <ErrorBoundary />
  },
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <RootIndex />
      },
      ...routeObjects
    ],
    errorElement: <ErrorBoundary />
  },
]);

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/">
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={false} />
        <Toaster
          richColors
          theme="light"
          toastOptions={{}}
        />
      </QueryClientProvider>
    </ClerkProvider>
  </StrictMode>,
)
