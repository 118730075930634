import { FolderTree, Globe, LucideProps, PenLine, RefreshCcwDot, User } from "lucide-react";
import React from "react";
import { Outlet, RouteObject } from "react-router-dom";
import { ContentBlogPostsNew } from "../routes/content.blogPosts.new";
import { Account } from "../routes/profile.account";
import { Subscription } from "../routes/profile.subscription";


const DummyComponent = () => {
  return (
    <div>Hello World!</div>
  )
}

enum RouteStatus {
  Live = "live",
  Beta = "beta",
  WIP = "wip",
  UpgradeRequired = "upgrade",
}

interface BaseNonIndexRouteConfig {
  label: string;
  path: string;
  ElementComponent: React.ComponentType;
  noMenu?: boolean;
  subItems?: BaseRouteConfig[];
  status?: RouteStatus;
}

interface BaseIndexRouteConfig {
  index: true;
  ElementComponent: React.ComponentType;
}

export type BaseRouteConfig = BaseNonIndexRouteConfig | BaseIndexRouteConfig;
export type FirstLevelNonIndexRouteConfig = {
  IconComponent: React.ComponentType<LucideProps>;
} & BaseNonIndexRouteConfig;
export type FirstLevelIndexRouteConfig = BaseIndexRouteConfig;
type FirstLevelRouteConfig = FirstLevelNonIndexRouteConfig | FirstLevelIndexRouteConfig;

interface RoutesSection {
  sectionName: string | null;
  path: string;
  ElementComponent?: React.ComponentType;
  items: FirstLevelRouteConfig[];
}

export type RoutesSectionsConfig = RoutesSection[];

const routesSections: RoutesSectionsConfig = [
  {
    sectionName: null,
    path: "",
    items: [
      {
        label: "Workspaces",
        path: "workspaces",
        IconComponent: FolderTree,
        subItems: [
          {
            index: true,
            noMenu: true,
            ElementComponent: DummyComponent,
          },
          {
            noMenu: true,
            label: "Create",
            path: "create",
            ElementComponent: DummyComponent,
          },
          {
            noMenu: true,
            label: "Edit",
            path: "edit/:projectId",
            ElementComponent: DummyComponent,
          },
        ]
      },
    ]
  },
  {
    sectionName: "Content",
    path: "content",
    items: [
      {
        label: "Blog Posts",
        path: "blog-posts",
        IconComponent: PenLine,
        subItems: [
          {
            noMenu: true,
            label: "New",
            path: "new",
            ElementComponent: ContentBlogPostsNew,
          },
        ]
      },
      {
        label: "Publishing",
        path: "publishing",
        ElementComponent: DummyComponent,
        IconComponent: Globe,
      },
      {
        label: "Repurposing",
        path: "repurposing",
        ElementComponent: DummyComponent,
        IconComponent: RefreshCcwDot,
      },
    ]
  },
  {
    sectionName: "User Management",
    path: "user-management",
    items: [
      {
        label: "Profile",
        path: "profile",
        ElementComponent: Outlet,
        IconComponent: User,
        subItems: [
          {
            label: "Account",
            path: "account",
            ElementComponent: Account,
          },
          {
            label: "Subscription",
            path: "subscription",
            ElementComponent: Subscription,
          }
        ]
      }
    ]
  },
]

const createRouteObjectForSubSection = (routeSubSection: BaseRouteConfig): RouteObject => {
  const ElementComponent = routeSubSection.ElementComponent;

  if ("index" in routeSubSection && routeSubSection.index) {
    return {
      index: true,
      element: <ElementComponent />,
    }
  } else {
    const firstLevelNonIndexRoute = routeSubSection as FirstLevelNonIndexRouteConfig;
    const children = (firstLevelNonIndexRoute.subItems || []).map(
      rdss => createRouteObjectForSubSection(rdss))
    const path = firstLevelNonIndexRoute.path;

    if (ElementComponent) {
      return {
        path,
        children,
        element: <ElementComponent />,
      }
    } else {
      return {
        path,
        children,
      }
    }
  }
}

const createRouteObjectForSection = (routeSection: RoutesSection) => {
  const path = routeSection.path;
  const children = routeSection.items.map(rss => createRouteObjectForSubSection(rss));
  const ElementComponent = routeSection.ElementComponent;

  if (ElementComponent) {
    return {
      path,
      children,
      element: <ElementComponent />,
    }
  } else {
    return {
      path,
      children,
    }
  }
}

const createRouteObjects = (routes: RoutesSectionsConfig) => {
  return routes.flatMap(r => {
    if (r.sectionName) {
      return createRouteObjectForSection(r)
    } else {
      return r.items.map(rss => createRouteObjectForSubSection(rss));
    }
  });
}

const routeObjects = createRouteObjects(routesSections)

export { routeObjects, routesSections, RouteStatus };
