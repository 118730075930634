import { useAuth, useUser } from '@clerk/clerk-react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@repo/ui/components/ui/accordion";
import { Badge } from "@repo/ui/components/ui/badge";
import { Button } from "@repo/ui/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@repo/ui/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@repo/ui/components/ui/popover";
import { cn } from "@repo/ui/lib/utils";
import { useQuery } from '@tanstack/react-query';
import { cva } from "class-variance-authority";
import { Check, ChevronsUpDown, Construction, LucideProps } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useShallow } from 'zustand/react/shallow';
import { Spinner } from "..";
import { RouteStatus } from '../../config/routes';
import { fetcher, serializeError } from '../../services/api';
import { usePrefsStore } from '../../store';
import { UsageCard } from "../usageCard/UsageCard";
import styles from "./style.module.css";

interface MenuItem {
  label: string;
  IconComponent: React.ComponentType<LucideProps>;
}

export interface MenuItemFlatObject extends MenuItem {
  isActive: boolean;
  path: string;
  status?: RouteStatus;
  badgeText?: string;
}

export interface MenuItemGroupObject extends MenuItem {
  value: string;
  subItems: {
    label: string,
    isActive: boolean,
    path: string,
    status?: RouteStatus,
  }[];
}

type MenuEntry = (MenuItemFlatObject | Omit<MenuItemGroupObject, "value">);

export interface MenuSection {
  sectionName: string | null;
  path: string;
  items: MenuEntry[];
}

interface MenuProps {
  productLogo: string;
  productName: string;
  sections: MenuSection[];
  badges?: Record<string, string>;
}

const Menu: React.FC<MenuProps> = ({
  productLogo,
  productName,
  sections,
  badges
}) => {
  const accordionDefaultValue =
    sections.flatMap(s =>
      s.items.find(entry =>
        "subItems" in entry && entry.subItems && entry.subItems.some(
          subItem => subItem.isActive)
      )?.label
    ).find(label => !!label);

  return (
    <aside className="sticky top-0 flex flex-col justify-between px-4 min-w-60 w-60 border max-h-screen bg-white">

      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex py-4">
            <Button
              variant="ghost"
              className="w-full justify-start"
              aria-label="Home"
              asChild
            >
              <Link to="/">
                <div className="flex items-center gap-1">
                  <img src={productLogo} className="size-12" alt="Logo" />
                  <span className="text-2xl font-semibold">{productName}</span>
                </div>
              </Link>
            </Button>
          </div>

          <MenuProjectSelection />
        </div>

        <Accordion type="single" collapsible defaultValue={accordionDefaultValue}>
          <div className="flex flex-col py-2 gap-4">
            {
              sections
                .map((s, i) => (
                  <div key={`group-${i}-${s.sectionName}`} className="flex flex-col gap-1">
                    <span className="px-1 text-muted-foreground text-xs uppercase">{s.sectionName}</span>
                    <div className="flex flex-col gap-0">
                      {
                        s.items.map((entry, i) => (
                          "subItems" in entry && entry.subItems.some(e => !("noMenu" in e && e.noMenu)) ?
                            <MenuItemGroup
                              key={`group-${i}-${entry.label}`}
                              value={entry.label}
                              {...entry}
                            /> :
                            <MenuItemFlat
                              key={`flat-${i}-${entry.label}`}
                              badgeText={badges ? badges[entry.label] || "" : ""}
                              {...entry}
                            />
                        ))
                      }
                    </div>
                  </div>
                )
                )
            }
          </div>
        </Accordion>
      </div>

      <UsageCard />

    </aside>
  )
}

Menu.displayName = "Menu";

interface MenuProjectSelectionProps {
}

const MenuProjectSelection: React.FC<MenuProjectSelectionProps> = ({}) => {
  const { getToken } = useAuth();
  const { isSignedIn } = useUser();
  const [open, setOpen] = useState(false)
  const [currentProject, setCurrentProject, resetCurrentProject] = usePrefsStore(
    useShallow((state) => [
      state.currentProject,
      state.setCurrentProject,
      state.resetCurrentProject
    ]),
  )

  const { data, error, status } = useQuery({
    queryKey: ["get", "projects"],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/projects", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  useEffect(() => {
    if (status === "success") {
      if (!currentProject) {
        if (data.data.length > 0) {
          setCurrentProject(projects[0]);
        }
      } else {
        if (!data.data.map(p => p.id).includes(currentProject.id)) {
          resetCurrentProject();
        }
      }
    }
  }, [status, currentProject, data?.data]);

  if (status === "pending") {
    return (
      <Spinner />
    )
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>
  }

  const projects = data.data;

  if (projects.length === 0) {
    return null;
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          {currentProject ? currentProject.name : "Select project..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="Search project..." />
          <CommandList>
            <CommandEmpty>No project found.</CommandEmpty>
            <CommandGroup>
              {projects.map((project) => (
                <CommandItem
                  key={project.id}
                  value={project.name}
                  onSelect={() => {
                    if (currentProject && project.name === currentProject.name) {
                      resetCurrentProject();
                    } else {
                      setCurrentProject(project)
                    }
                    setOpen(false)
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      currentProject && project.name === currentProject.name ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {project.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

MenuProjectSelection.displayName = "MenuProjectSelection";

type MenuItemFlatProps = MenuItemFlatObject;

const menuItemFlatVariantsButton = cva(
  "w-full justify-start",
  {
    variants: {
      isActive: {
        false: "",
        true: "bg-secondary text-secondary-foreground hover:bg-secondary hover:text-secondary-foreground",
      }
    },
    defaultVariants: {
      isActive: false
    }
  }
)

const menuItemFlatVariantsSpan = cva(
  "text-sm",
  {
    variants: {
      isActive: {
        false: "font-normal",
        true: "font-semibold",
      }
    },
    defaultVariants: {
      isActive: false
    }
  }
)

const MenuItemFlat: React.FC<MenuItemFlatProps> = ({
  IconComponent,
  label,
  path,
  isActive,
  status,
  badgeText
}) => {
  return (
    <Button
      variant="ghost"
      size="sm"
      className={menuItemFlatVariantsButton({ isActive })}
      aria-label={label}
      asChild={!status || status === RouteStatus.Live}
    >
      {
        (!status || status === RouteStatus.Live) ? (
          <Link to={path}>
            <div className="w-full flex items-center justify-between gap-2">
              <div className="flex items-center gap-2">
                <IconComponent strokeWidth={1.5} className="size-5" />
                <span className={menuItemFlatVariantsSpan({ isActive })}>{label}</span>
              </div>
              {badgeText && <Badge variant={isActive ? "default" : "info"}>{badgeText}</Badge>}
            </div>
          </Link>
        ) : (
          <div className="flex items-center w-full gap-4">
            <div className="flex items-center gap-2">
              <IconComponent strokeWidth={1.5} className="size-5" />
              <span className={menuItemFlatVariantsSpan({ isActive })}>{label}</span>
            </div>
            <div className="flex bg-warning text-warning-foreground p-1 rounded-md">
              <Construction strokeWidth={1.5} className="size-4" />
            </div>
          </div>
        )
      }
    </Button>
  )
}

MenuItemFlat.displayName = "MenuItemFlat";

type MenuItemGroupProps = MenuItemGroupObject;

const MenuItemGroup: React.FC<MenuItemGroupProps> = ({
  value,
  IconComponent,
  label,
  subItems,
}) => {
  const hasActive = subItems.some(item => item.isActive)
  return (
    <AccordionItem
      data-active={hasActive}
      value={value}
      className={cn(styles.accordion, "border-0")}
    >
      <AccordionTrigger className="px-3 py-0 h-9 rounded-md hover:bg-muted hover:no-underline">
        <div className="flex items-center gap-2">
          <IconComponent strokeWidth={1.5} className="size-5" />
          <span className="text-sm font-normal">{label}</span>
        </div>
      </AccordionTrigger>
      <AccordionContent className="py-1">
        <div className="flex flex-col gap-0">
          {
            subItems.map((si, i) => (
              <MenuGroupSubItem
                key={`${i}-${si.label}`}
                label={si.label}
                isActive={si.isActive}
                path={si.path}
                status={si.status}
              />
            ))
          }
        </div>
      </AccordionContent>
    </AccordionItem>
  )
}

MenuItemGroup.displayName = "MenuItemGroup";

interface MenuGroupSubItemProps {
  label: string;
  isActive: boolean;
  path: string;
  status?: RouteStatus;
}

const menuGroupSubItemVariants = cva(
  "w-full justify-start pl-11",
  {
    variants: {
      isActive: {
        false: "font-normal",
        true: "bg-secondary font-semibold text-secondary-foreground hover:bg-secondary hover:text-secondary-foreground"
      }
    },
    defaultVariants: {
      isActive: false
    }
  }
)

const MenuGroupSubItem: React.FC<MenuGroupSubItemProps> = ({
  label,
  isActive,
  path,
  status,
}) => {
  return (
    <Button
      variant="ghost"
      size="sm"
      className={menuGroupSubItemVariants({ isActive })}
      aria-label={label}
      asChild={!status || status === RouteStatus.Live}
    >
      {
        (!status || status === RouteStatus.Live) ? (
          <Link to={path}>
            <span className="text-sm">{label}</span>
          </Link>
        ) : (
          <div className="flex items-center w-full gap-4">
            <span className="text-sm">{label}</span>
            <div className="flex bg-warning text-warning-foreground p-1 rounded-md">
              <Construction strokeWidth={1.5} className="size-4" />
            </div>
          </div>
        )
      }
    </Button>
  )
}

MenuGroupSubItem.displayName = "MenuGroupSubItem";

export { Menu };
