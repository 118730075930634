import { Avatar, AvatarFallback, AvatarImage } from "@repo/ui/components/ui/avatar";
import { Button } from "@repo/ui/components/ui/button";
import { Card, CardContent } from "@repo/ui/components/ui/card";
import { Progress } from "@repo/ui/components/ui/progress";
import { Separator } from "@repo/ui/components/ui/separator";
import { Textarea } from "@repo/ui/components/ui/textarea";
import { cva } from "class-variance-authority";
import { X } from 'lucide-react';
import { AnimatePresence, motion } from "motion/react";
import React, { useEffect, useState } from "react";
import echoAvatar from '/echo_avatar.png';


const motionChatControlsConfig = {
  duration: 0.5,
  variants: {
    maximized: {
      gap: "6rem", // gap-24
      "padding-bottom": "6rem" // pb-24
    },
    minimized: {
      gap: "0.5rem", // gap-2
      "padding-bottom": "1rem" // pb-4
    }
  }
}

const motionChatControlsDialogBoxConfig = {
  duration: 0.5,
  variants: {
    maximized: {
      height: "15rem", // h-60
    },
    minimized: {
      height: "2.5rem", // h-10
    }
  }
}

interface MainContainerChatProps {
  isMaximized: boolean;
  canHide: boolean;
  onHideChat: () => void;
}

const MainContainerChat: React.FC<MainContainerChatProps> = ({ isMaximized, canHide, onHideChat }) => {
  const [userMessage, setUserMessage] = useState("");
  const [aiMessage, setAIMessage] = useState("");
  return (
    <motion.div
      className="flex flex-col w-full h-full items-center justify-end"
      variants={motionChatControlsConfig.variants}
      animate={isMaximized ? "maximized" : "minimized"}
      transition={{ duration: motionChatControlsConfig.duration }}
      initial={false}
    >
      <div className="flex flex-col w-full max-w-lg gap-1">
        <AnimatePresence>
          {canHide && (
            <motion.div
              className="flex w-full justify-end"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <Button
                className="pointer-events-auto"
                variant="outline"
                size="small-icon"
                onClick={onHideChat}
              >
                <X size="14" />
              </Button>
            </motion.div>
          )}
        </AnimatePresence>

        <motion.div
          className="flex flex-col items-center w-full"
          variants={motionChatControlsDialogBoxConfig.variants}
          animate={isMaximized ? "maximized" : "minimized"}
          transition={{ duration: motionChatControlsDialogBoxConfig.duration }}
          initial={false}
        >
          <Textarea
            placeholder="AI responses"
            className="resize-none max-w-lg bg-white h-full pointer-events-auto disabled:cursor-default disabled:opacity-100"
            value={aiMessage}
            onChange={(ev) => {
              setAIMessage(ev.target.value)
            }}
            readOnly={true}
            disabled={true}
          />
        </motion.div>
      </div>

      <Textarea
        placeholder="Write or generate reply"
        className="resize-none max-w-lg bg-white h-20 pointer-events-auto"
        value={userMessage}
        onChange={(ev) => {
          setUserMessage(ev.target.value)
        }}
        readOnly={false}
        disabled={false}
      />
    </motion.div>
  )
}

MainContainerChat.displayName = "MainContainerChat";


const mainContainerUIVariants = cva(
  "relative h-full transition-all duration-500",
  {
    variants: {
      isTransparent: {
        false: "",
        true: "border-0 bg-transparent"
      }
    },
    defaultVariants: {
      isTransparent: true
    }
  }
)

interface MainContainerProps {
  isMaximized: boolean;
}

const MainContainer: React.FC<MainContainerProps> = ({ isMaximized }) => {
  const [canHideChat, setCanHideChat] = useState(!isMaximized)
  const [isChatHidden, setIsChatHidden] = useState(false)

  useEffect(() => {
    setCanHideChat(!isMaximized)
    setIsChatHidden(false)
  }, [isMaximized])

  return (
    <Card className={mainContainerUIVariants({ isTransparent: isMaximized })}>
      <CardContent>
        {isMaximized ? "" : "Foo Bar"}
      </CardContent>

      <div className="absolute bottom-0 top-0 w-full h-full pointer-events-none">
        <AnimatePresence>
          {
            !isChatHidden && (
              <motion.div
                className="w-full h-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <MainContainerChat
                  isMaximized={isMaximized}
                  canHide={canHideChat && !isChatHidden}
                  onHideChat={() => setIsChatHidden(true)}
                />
              </motion.div>
            )
          }
        </AnimatePresence>

      </div>

      <AnimatePresence>
        {
          isChatHidden && canHideChat && (
            <motion.div
              className="absolute bottom-4 right-4 pointer-events-none hover:scale-110 transition-all"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <Avatar
                className="outline outline-4 outline-primary pointer-events-auto cursor-pointer"
                onClick={() => setIsChatHidden(false)}
              >
                <AvatarImage src={echoAvatar} />
                <AvatarFallback>CN</AvatarFallback>
              </Avatar>
            </motion.div>
          )
        }
      </AnimatePresence>
    </Card>
  )
}

MainContainer.displayName = "MainContainer"


const sideContainerUIVariants = cva(
  "h-full transition-all duration-500",
  {
    variants: {
      isTransparent: {
        false: "",
        true: "border-0 bg-transparent"
      }
    },
    defaultVariants: {
      isTransparent: true
    }
  }
)

interface SideContainerProps {
  isCardTransparent: boolean;
}

const SideContainer: React.FC<SideContainerProps> = ({ isCardTransparent }) => {
  return (
    <Card className={sideContainerUIVariants({ isTransparent: isCardTransparent })}>
      <CardContent>
        <div className="flex flex-col gap-2 justify-center items-center py-4">
          <span>Researching web...</span>
          <Progress value={60} className="w-full h-2" />
        </div>
        <Separator />
      </CardContent>
    </Card >
  )
}

SideContainer.displayName = "SideContainer"


const motionLayoutConfig = {
  duration: 0.5,
  variants: {
    main: {
      expanded: {
        width: "100%"
      },
      nonExpanded: {
        width: "66%"
      }
    },
    side: {
      expanded: {
        width: "34%"
      },
      nonExpanded: {
        width: "0%"
      }
    }
  }
}

interface ContentBlogPostsNewProps {}

const ContentBlogPostsNew: React.FC<ContentBlogPostsNewProps> = () => {
  const [isMainExpanded, setIsMainExpanded] = useState(true);

  return (
    <div className="flex flex-col gap-4 w-full h-full">

      <button onClick={() => setIsMainExpanded(v => !v)}>Click</button>

      <div className="flex gap-4 w-full h-full">
        <motion.div
          className="h-full overflow-hidden"
          variants={motionLayoutConfig.variants.main}
          animate={isMainExpanded ? "expanded" : "nonExpanded"}
          transition={{ duration: motionLayoutConfig.duration }}
          initial={false}
        >
          <MainContainer
            isMaximized={isMainExpanded}
          />
        </motion.div>

        <motion.div
          className="h-full overflow-hidden"
          variants={motionLayoutConfig.variants.side}
          animate={isMainExpanded ? "nonExpanded" : "expanded"}
          transition={{ duration: motionLayoutConfig.duration }}
          initial={false}
        >
          <SideContainer isCardTransparent={isMainExpanded} />
        </motion.div>
      </div>
    </div>
  )
}

ContentBlogPostsNew.displayName = "ContentBlogPostsNew";

export { ContentBlogPostsNew };
